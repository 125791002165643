import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import './Header.scss';
import { Link } from 'react-router-dom';
import TopMenu from '../top-menu/TopMenu';
import Search from '../search/Search';
import { IData } from '../../interfaces/IData';

type HeaderProps = {
  content: IData | undefined;
  search: (data: string) => void;
}

// const Header = (props: HeaderProps) => {
const Header = ({content, search}: HeaderProps) => {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  const IsShowSearchBar = () => {
    const pathName = usePathname();
    return pathName === '/';
  };

  const updateInput = async (input: string) => {
    search(input);
  };

  if(!content) {
    return null;
  }

  return ( content && 
    <div className="header animate__animated animate__backInDown fixed">
      <TopMenu></TopMenu>
      <div
        className="main-bg"
        style={{ backgroundImage: 'url("' + content['background'] + '")' }}
      />
      <div className="top-container">
        <Link
          to={{
            pathname: `/`,
          }}
        >
          <div
            className="logo"
            style={{ backgroundImage: 'url("' + content['logo'] + '")' }}
          />
        </Link>
        <div className="header">
          <div className="title">{content['channel_title']}</div>
          <div className="subtitle">{content['subscribers']}</div>
        </div>
        <div className="desktop-search">
          {IsShowSearchBar() && (
            <Search setKeyword={updateInput} />
          )}
        </div>
        <a href={content['subscription']} className="subscribe">
          SUBSCRIBE
        </a>
      </div>
      <div className="mobile-search">
        {IsShowSearchBar() && <Search setKeyword={updateInput} />}
      </div>
    </div>
  );
};

export default Header;
