import React from 'react';
import {Link} from "react-router-dom";
import "./TopMenu.scss";

function TopMenu() {
    const contactForm = "b8ee21ec-e549-49d0-8297-63a78a25a24f";

    return (
        <div className="top-menu">
            <Link to={{
                pathname: `/`
            }}>
                HOME
            </Link>
            <Link to={{
                pathname: `/info`
            }}>
                INFO
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>CONTACT</Link> : null}
        </div>
    );
}

export default TopMenu;
